import * as R from 'ramda'
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'
import loadable from '@loadable/component'
import React, {useEffect, useState} from 'react'

import {getServices} from 'helpers/services'
import defaultTheme from 'config/theme'
import Footer from 'components/Layout/FooterChina'
import Header from 'components/Layout/HeaderChina'
import palette1 from 'config/palette1'
import useAosInit from 'hooks/useAosInit'
import useIsMobile from 'hooks/useIsMobile'
import useIsPartnersPage from 'hooks/useIsPartnersPage'
import useMountComponent from 'hooks/useMountComponent'

import useStyles from './styles'

const LayoutKeyrusChina = props => {
  const ScrollTop = loadable(() => import('components/UI/ScrollTopButton'))
  const isMobile = useIsMobile()
  const isPartnersPage = useIsPartnersPage()
  const {children, data, pageHasNoNavigation, layoutData, pageContext} = props
  const countryData = R.path(['contentfulCountry'], data)

  const services = R.pathOr(null, ['contentfulCountry', 'services'], data)

  const topLevelServices =
    services && R.pathOr(null, ['topLevelServices'], services)

  const countryServices =
    topLevelServices && getServices(data, topLevelServices)

  const classes = useStyles()

  const [showLayout, setShowLayout] = useState(false)

  const isLayoutMounted = useMountComponent()
  const showNav =
    process.env.GATSBY_IN_SITE_MAINTENANCE === 'false' && !pageHasNoNavigation

  const handleLayoutDisplay = bool =>
    bool
      ? {visibility: 'visible', marginTop: isMobile ? 25 : 100}
      : {visibility: 'hidden'}

  useEffect(() => {
    setShowLayout(isLayoutMounted)
  }, [isLayoutMounted])

  useAosInit(showLayout)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <ThemeProvider theme={palette1}>
          <div
            style={handleLayoutDisplay(showLayout)}
            className={showNav && classes.wrapper}
          >
            {showNav && (
              <Header
                pageContext={pageContext}
                countryData={countryData}
                data={layoutData}
                pageData={data}
                countryServices={countryServices}
              />
            )}
            {children}
            {showNav && (
              <Footer
                pageContext={pageContext}
                countryData={countryData}
                data={layoutData}
              />
            )}
            {!isPartnersPage && <ScrollTop />}
          </div>
        </ThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default LayoutKeyrusChina
