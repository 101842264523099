import * as R from 'ramda'
import React from 'react'

import {socialPropTypes} from 'helpers/propTypes'
import classNames from 'classnames'

import useStyles from './styles'

const Social = ({
  hasBorderRadius,
  hasMarginTop,
  hasPrimaryColor,
  isCentered,
  isLarge,
  isSpaced,
  socialMediaLinks,
}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)

  return (
    <div
      className={classNames(classes.slideSocials, {
        [classes.marginTop]: hasMarginTop,
        [classes.isCentered]: isCentered,
        [classes.primaryColor]: hasPrimaryColor,
        [classes.hasBorderRadius]: hasBorderRadius,
        [classes.spaced]: isSpaced,
      })}
    >
      {socialMediaLinks &&
        mapIndexed((line, index) => {
          const {name, icon, id} = line
          const iconFileUrl = R.pathOr(null, ['file', 'url'], icon)
          const lowercaseName = R.toLower(name)

          return (
            <a key={line.id} href={line.link} rel="noreferrer" target="_blank">
              <img
                className={classNames({
                  [classes.isLarge]: isLarge,
                  [classes.linkedinHover]:
                    lowercaseName === 'linkedin' && !hasPrimaryColor,
                  [classes.instagramHover]:
                    lowercaseName === 'instagram' && !hasPrimaryColor,
                })}
                key={`${id} ${index}`}
                src={iconFileUrl}
                alt={name}
                loading="lazy"
              />
            </a>
          )
        }, socialMediaLinks)}
    </div>
  )
}

Social.propTypes = socialPropTypes.isRequired

export default Social
